@import url('https://fonts.googleapis.com/css?family=Lato:100&display=swap');
.back-button {
    position: absolute;
    left: 20px;
    top: 0;
    width: 100px;
    height: 60px;
    cursor: pointer;
    background-color: rgba(55, 48, 48, 0.7);
    color: #0385f4;
    border: 1px solid #000000;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  .border-svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .back-button:hover {
    transition: 1s ease-in-out;
    background: #0385f4;
  }
  
  .back-button:hover .border-svg {
    stroke-dashoffset: -480;
  }
  
  .back-button span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
@import url(https://fonts.googleapis.com/css?family=Lato:100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
.back-button {
    position: absolute;
    left: 20px;
    top: 0;
    width: 100px;
    height: 60px;
    cursor: pointer;
    background-color: rgba(55, 48, 48, 0.7);
    color: #0385f4;
    border: 1px solid #000000;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  .border-svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .back-button:hover {
    transition: 1s ease-in-out;
    background: #0385f4;
  }
  
  .back-button:hover .border-svg {
    stroke-dashoffset: -480;
  }
  
  .back-button span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
.btn-custom {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    color: #0385f4;
    border: 1px solid #0385f4;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  .border-svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn-custom:hover {
    transition: 1s ease-in-out;
    background: #0385f4;
  }
  
  .btn-custom:hover .border-svg {
    stroke-dashoffset: -480;
  }
  
  .btn-custom span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #635bff;
  --radius: 3px;
}


main {
  width: 480px;
}

form > * {
  margin: 10px 0;
}

button {
  background-color: #635bff;
  background-color: var(--accent-color);
}

button {
  background: #635bff;
  background: var(--accent-color);
  border-radius: 3px;
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}
button:active {
  -webkit-transform: translateY(0px) scale(0.98);
          transform: translateY(0px) scale(0.98);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
}

a {
  color: #635bff;
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

#payment-form {
  align-items: center;
  background-color: lightgray;
  border: #f6f9fc solid 1px;
  border-radius: 3px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: 3px;
  border-radius: var(--radius);
  font-size: 0.7em;
}
header {
    padding: 1rem 0;
  }
  
  .main-content {
    margin-top: 2rem;
  }

.product {
    background-color: white;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    padding: 1rem 0;
    margin: 1rem 0;
  }
  
  .product-detail {
    padding: 0 2rem;
  }
  
  .product-price {
    font-weight: bold;
    font-size: 140%;
  }

@import url('https://fonts.googleapis.com/css?family=Lato:100&display=swap');
.btn-custom {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    color: #0385f4;
    border: 1px solid #0385f4;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  .border-svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn-custom:hover {
    transition: 1s ease-in-out;
    background: #0385f4;
  }
  
  .btn-custom:hover .border-svg {
    stroke-dashoffset: -480;
  }
  
  .btn-custom span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }